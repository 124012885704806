(function(){
	'use strict';

	angular.module('directives.navigation', ['services.MainMenu'])
	    .directive('navigation', navigation)
	    .controller('NavigationController',NavigationController);

    function navigation () {
        return {
            templateUrl: 'js/templates/navigation.tpl.html',
            restrict: 'AE',
            controller: 'NavigationController',
            controllerAs: 'vm'
        };
    }

    NavigationController.$inject =  ['$location', 'MainMenuService', 'AuthenticationService', 'ConfirmationService'];

    function NavigationController ($location, MainMenuService, AuthenticationService, ConfirmationService) {
    	var vm = this;

    	this.menuService = MainMenuService;
    	this.isActive = isActive;
        this.logout = logout;



        function isActive(page) {
        	var currentRoute = $location.path().substring(1) || '/';
        	return (page === currentRoute || page === currentRoute.split('/')[0]);
        }

        function logout(){
            ConfirmationService.confirmThis({question:"Weet je zeker dat je wilt uitloggen? Niet opgeslagen wijzigingen worden ongedaan gemaakt.", icon:"sign out"}, function(confirmed){
                if(confirmed){
                    AuthenticationService.logoff();
                }
            });
        }
    }

})();