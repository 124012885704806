(function(){
    'use strict';

    angular.module('services.http', [])
        .factory('HttpService', HttpService);

    function HttpService($resource, $location, $q, ENVIRONMENT) {

        var interceptor = {
            responseError: function (response) {
                if(response.status===401){
                    $location.path('/login');
                }
                return $q.reject(response);
            },
            response: function (response) {
                return response.data;
            }
        };

        return function (url, paramDefaults, actions, options) {
            actions = angular.extend({}, actions, {
            'get':    { method: 'GET', withCredentials: ENVIRONMENT.WITH_CREDENTIALS, interceptor: interceptor },
            'query':  { method: 'GET', interceptor: interceptor, withCredentials: ENVIRONMENT.WITH_CREDENTIALS},
            'update': { method: 'PUT', withCredentials: ENVIRONMENT.WITH_CREDENTIALS, interceptor: interceptor},
            'save':   { method: 'POST', withCredentials: ENVIRONMENT.WITH_CREDENTIALS, interceptor: interceptor},
            'remove': { method: 'DELETE', withCredentials: ENVIRONMENT.WITH_CREDENTIALS, interceptor: interceptor},
            'delete': { method: 'DELETE', withCredentials: ENVIRONMENT.WITH_CREDENTIALS, interceptor: interceptor}
            });

            return $resource(url, paramDefaults, actions, options);
        };
    }
    HttpService.$inject = ['$resource','$location', '$q', 'ENVIRONMENT'];
    
})();