(function () {
    'use strict';
    angular.module('directives.relationPicker', [])
        .directive('relationPicker', relationPicker)
        .controller('RelationPickerController', RelationPickerController);

    function relationPicker(){
        return {
            restrict: 'AE',
            replace: true,            
            scope: {
                model: '=ngModel',
                relation: '@relation',
                name: '@name',
                mode: '@mode'
            },
            bindToController: true,
            templateUrl: "js/templates/relation-picker.tpl.html",
            controller: 'RelationPickerController',
            controllerAs: 'vm'
        };
    }
    function RelationPickerController($scope, RelationService){

        var vm = this;

        //Bindable props
        this.choices = {};
        this.choices.selectedRelations = [];

        this.items = [];
        this.filteredRelations = [];

        //Bindable methods
        this.initValues = initValues;
        this.filterRelations = filterRelations;
        this.removeRelation = removeRelation;

        var initializing = true;

        $scope.$watch('vm.model', function(value) {
                
            // if (!value) {
            //     vm.model = vm.mode==='multi'?[]:'';
            // }
            if(initializing){
                initializing = false;

                RelationService.get(vm.relation)
                    .then(
                        function (data){
                            vm.items = data.items;
                            vm.initValues();
                        }, function(err){

                        });
            }
        });
        $scope.$watch('vm.choices', function(newValue, oldValue){
           if(newValue !== oldValue && newValue){
                
                if(vm.mode === 'multi'){
                    if(!vm.model) {
                        vm.model = [];
                    }
                    angular.forEach(newValue.selectedRelations, function(chosen){
                        if(vm.model.indexOf(chosen.value)===-1){
                            vm.model.push(chosen.value);
                        }
                    });
                    
                } 
                vm.filterRelations();
           }
        }, true);

        function initValues() {
            if(vm.mode === 'multi'){
                angular.forEach(vm.model, function(selected){
                    angular.forEach(vm.items, function (item){
                        if(item._id===selected){
                            vm.choices.selectedRelations.push({title:item.name,value:item._id});
                        }
                    });
                });
                
            } else {
                vm.choices.selectedRelations = vm.model;
            }

            vm.filterRelations();
            
        }

        function filterRelations (){
            vm.filteredRelations = [];
            if(vm.mode ==='multi' && vm.model) {
                angular.forEach(vm.items, function(category){
                    if(vm.model.indexOf(category._id)===-1){
                        vm.filteredRelations.push(category);
                    }
                });
            } else {
                vm.filteredRelations = vm.items;
            }
            
        }
        
        function removeRelation (index) {
            vm.choices.selectedRelations.splice(index,1);
            vm.model.splice(index,1);

            vm.filterRelations();
        }

    }
    RelationPickerController.$inject = ["$scope", "RelationService"];
})();