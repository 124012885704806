(function () {
	'use strict';
	angular.module('directives.notifier',[])
		.directive('notifier', notifier)
		.controller('NotifierController', NotifierController);

	function notifier (NotificationService ){
		return {
			restrict: 'AE',
			replace: true,
			templateUrl: 'js/templates/notifier.tpl.html',
			controller: 'NotifierController',
			controllerAs: 'vm'
		};

	}
	notifier.$inject = ['NotificationService'];

	function NotifierController( $scope, NotificationService ) {
		var vm = this;

		vm.notifications = NotificationService;

		vm.popSuccess = function (index) {
			NotificationService.popSuccess(index);
		};
		vm.popError = function (index) {
			NotificationService.popError(index);
		};

	}
	NotifierController.$inject = ['$scope', 'NotificationService'];

	

})();