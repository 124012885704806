(function () {
    'use strict';

    angular.module('services.activeUser', [])
        .factory('ActiveUserFactory', ActiveUserFactory);

    function ActiveUserFactory ($http, ENVIRONMENT, MESSAGES) {

        var activeUsername;
        var activeUser;
        var observers = [];

        return {
            activeUser: activeUser,
            setActiveUser: setActiveUser,
            getActiveUser: getActiveUser,
            registerObserver: registerObserver,

            isManager:isManager
        };

        function setActiveUser(user) {
            if(user){
                activeUser = user;
                notifyObservers(MESSAGES.ACTIVE_USER_LOADED);
            } else {
                $http.get(ENVIRONMENT.AUTH_URL+'/login', { withCredentials:ENVIRONMENT.WITH_CREDENTIALS })
                    .then(function(success){
                        activeUser = success.data;
                        notifyObservers(MESSAGES.ACTIVE_USER_LOADED);
                    });   
            }    
        }
        function getActiveUser(){
            return activeUser;
        }
        function isManager(){
            if(!activeUser) return;
            return (activeUser.manager);
        }
        function registerObserver(callback){
            observers.push(callback);
        }
        function notifyObservers(type,body){
            var i;
            var len = observers.length;
            for(i = 0 ; i<len; i++){
                observers[i](type,body);
            }
        }

    }

    ActiveUserFactory.$inject = ['$http', 'ENVIRONMENT', 'MESSAGES'];

})();
