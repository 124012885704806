(function () {
	'use strict';

	angular.module('userList', [])
		.config(config)
		.controller('UserListController', UserListController);

	function config($stateProvider) {

		$stateProvider
			.state('users', {
				url:'/users',
				templateUrl: 'js/templates/user-list.tpl.html',
				controller: 'UserListController',
				controllerAs: 'vm'
			});
	}
	config.$inject = ['$stateProvider'];

	function UserListController($location, User, ActiveUserFactory) {
		var vm = this;

		//Bindables
		vm.preloader = true;
		vm.createNewUser = createNewUser;

		//Initializer

		console.log("starting load");

		User.query()
			.$promise.then(
			function (success){
				console.log(success);
				vm.users = success;
				vm.preloader = false;
			}, function (error){

			});

		//Implementation
		function createNewUser() {
			$location.path('users/new');
		}

	}
	UserListController.$inject = ['$location','User', 'ActiveUserFactory'];


})();