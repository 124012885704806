(function (){
	'use strict';

	angular.module('userSingle', ['services.password'])

		.config(['$stateProvider', function ($stateProvider) {
	        $stateProvider
	            .state('userSingle',{
	                url: '/users/:id',
	                templateUrl: 'js/templates/user-single.tpl.html',
	                controller: 'UserEditController',
	                controllerAs: 'vm'
	            });
	    }])
	    .controller('UserEditController', UserEditController);


	function UserEditController ($scope, $location, $stateParams, User, PasswordService, NotificationService, $q){

        var vm = this;
        var user_id = $stateParams.id;

        vm.preloader = true;
        vm.passInputType = 'password';
        vm.choices = [];

        //Bindables
        vm.checkPass = checkPass;
        vm.generatePass = generatePass;
        vm.toggleHidePassword = toggleHidePassword;

        vm.saveUser = saveUser;
        vm.updateUser = updateUser;
        vm.cancelAll = cancelAll;


        (function init(){
            var promises = [];
            
		    if(user_id !== "new") {
	        	vm.isNew = false;
	        	promises.push(User.get({id: user_id}).$promise.then(
                    function (success){
                        vm.user = success;
                        vm.pageTitle = "Bewerk gebruiker '" + vm.user.username + "'";
                        vm.choices.selectedRole = vm.user.user_role_ids;
                    },
                    function (error) {
                        NotificationService.pushError(error.message);
                    }));
	        } else {
	        	vm.isNew = true;
	        	vm.user = new User();
	        	vm.pageTitle = "Maak een nieuwe gebruiker";
	        }
        })();

        function checkPass(){

        	if(vm.user.firstpass === vm.user.password) {
        		vm.passMatch = true;
        		vm.checkedNeg = false;
        	} else {
        		vm.passMatch = false;
        		vm.checkedNeg = true;
        	}

        }
        function generatePass() {

        	vm.user.firstpass = vm.user.password = PasswordService.generateRandom();
        	vm.passMatch = true;
        	vm.generated = true;
        	vm.toggleHidePassword();

        }
        function toggleHidePassword() {

        	if(vm.passInputType === 'password'){

        		vm.passInputType = 'text';

        	} else {

        		vm.passInputType = 'password';

        	}
        	
        }
        function saveUser() {
            if ($location.path() === '/users/new') {

                User.save({}, vm.user)
                    .$promise.then(
                        function (success) {
                            NotificationService.pushSuccess("De gebruiker is succesvol toegevoegd");
                            $location.path('/users/' + success._id);
                        },
                        function (err) {
                            NotificationService.pushError("Er is iets misgegaan. De gebruiker is niet opgeslagen");
                        });
            } else {

                vm.updateUser();

            }

        }
        function updateUser() {
            vm.user.user_role_ids = vm.choices.selectedRole;

        	User.update ({ id:vm.user._id } , vm.user)
                    .$promise.then(
                        function(success){
                             NotificationService.pushSuccess("De gebruiker is succesvol opgeslagen");

                        },
                        function(err){

                            NotificationService.pushError("Er is iets misgegaan. De gebruiker is niet opgeslagen");
                            
                        });
        }

        function cancelAll() {

        	$location.path('/users');

        }
    }
	UserEditController.$inject = ['$scope', '$location', '$stateParams', 'User', 'PasswordService', 'NotificationService', '$q'];

})();