(function () {
    'use strict';
    angular.module('filters.sanitize', [])
        .filter('sanitize', sanitize);

    function sanitize($sce){
        return function(htmlCode){
            return $sce.trustAsHtml(htmlCode);
        };
    }
    sanitize.$inject = ['$sce'];
})();