(function(){
    'use strict';

    angular.module('services.confirmation', [])
        .factory('ConfirmationService', ConfirmationService);

    function ConfirmationService (EventService) {
        var responder;
        //Public API
        return {
            confirmThis: confirmThis,
            confirm: confirm,
            subscribe: subscribe
        };

        //Implementation details
        function confirmThis(question, callback){
            responder = callback;
            EventService.notify('CONFIRMATION_NEEDED', question);
        }

        function confirm(bool){
            responder(bool);
        }

        function subscribe(evt,scope,callback){
            EventService.subscribe(evt, scope, callback);
        }

    }
    ConfirmationService.$inject = ['EventService'];

})();