(function () {
    'use strict';
    angular.module('utils.environment', [])

    .constant('ENVIRONMENT', {
        BASE_URL: 'https://control-panel.julius-leiderschap.nl',
        AUTH_URL: 'https://api.julius-leiderschap.nl/auth',
        API_URL : 'https://api.julius-leiderschap.nl',
        WITH_CREDENTIALS : true
    });
})();
