(function(){
	'use strict';

	angular.module('services.relations', [])
		.service('RelationService', RelationService);

	function RelationService(){
		var svc = this;

		this.get = get;

		function get (related, options) {

			var opts = options || {};

			switch(related) {
				case 'Article':
					return Article.query(opts).$promise;
				default:
					return;
			}
		}

	}
	RelationService.$inject = [];
})();