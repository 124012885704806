(function(){
  'use strict';

  angular.module('services.event', [])
    .factory('EventService', EventService);

  function EventService($rootScope){
    return {
       subscribe: function(evt, scope, callback) {
          var handler = $rootScope.$on(evt, callback);
          scope.$on('$destroy', handler);
       },
       notify: function(evt, value) {
          console.log('Throwing', evt);
          $rootScope.$emit(evt, value);
       }
    };
  }
  EventService.$inject = ['$rootScope'];
})();