(function (){
    'use strict';

    angular.module('directives.confirmation',[])
        .controller('ConfirmationBoxController', ConfirmationBoxController)
        .directive('confirmationBox', confirmationBox);

    function confirmationBox(){

        function link(scope){

        }
        return {
            restrict:'E',
            replace:true,
            templateUrl: 'js/templates/confirmation-box.tpl.html',
            controller: 'ConfirmationBoxController',
            controllerAs: 'vm',
            scope: {
            },
            bindToController: true,
            link:link
        };

    }

    function ConfirmationBoxController($scope,ConfirmationService){
        var vm = this;

        //Bindable properties
        this.confirmMessage = '';
        this.modalClass = 'hidden';

        //Bindable methods
        this.confirmationNeeded = confirmationNeeded;
        this.respond = respond;

        //Initializer IIFE
        (function init() {
            ConfirmationService.subscribe('CONFIRMATION_NEEDED',$scope, vm.confirmationNeeded);
        })();

        function confirmationNeeded(evt, confirmation){
            vm.confirmationIcon = confirmation.icon !== undefined ? confirmation.icon + ' icon' : 'help icon';
            vm.confirmMessage = confirmation.question;
            vm.modalClass = 'active visible';
        }
        function respond (bool){
            vm.modalClass = 'hidden';
            ConfirmationService.confirm(bool);
        }
    }
    ConfirmationBoxController.$inject = ['$scope','ConfirmationService'];


})();