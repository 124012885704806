(function(){

  'use strict';

  angular.module('resources.user', [])
    .factory('User', User)
    .factory('UserRole', UserRole);

  function User (HttpService, ENVIRONMENT) {
    var resourceUrl = ENVIRONMENT.API_URL + '/users/:id';
    return HttpService(resourceUrl, {id: '@id'});
  }
  User.$inject = ['HttpService', 'ENVIRONMENT'];

  function UserRole (HttpService, ENVIRONMENT) {
    var resourceUrl = ENVIRONMENT.API_URL + '/userroles/:id';
    return HttpService(resourceUrl, {id: '@id'});
  }

  UserRole.$inject = ['HttpService', 'ENVIRONMENT'];

})();