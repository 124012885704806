(function (){
	'use strict';

	angular.module('services.localisation', [])
		.service('LocalisationService', LocalisationService);

	function LocalisationService ($http) {
		var svc = this;

		this.transl = transl;
		this.getLanguages = getLanguages;
		this.getDefaultLanguage = getDefaultLanguage;

		(function init(){
			$http.get('/languages.json')
				.success(function(json){
					svc.translations = json.translations;
					svc.languages = json.languages;
					svc.defaultLanguage = json.default_language;
				});
		})();
		function transl(string){
			return svc.translations['en-US'][string] || string;
		}
		function getLanguages(){
			return svc.languages;
		}
		function getDefaultLanguage(){
			return svc.defaultLanguage;
		}
	}

	LocalisationService.$inject = ['$http'];
})();