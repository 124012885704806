(function(){
	'use strict';

	angular.module('directives.linkPicker', [])
		.directive('linkPicker', linkPicker)
		.controller('LinkPickerController', LinkPickerController);

	function linkPicker(){
		return {
			restrict:'E',
			replace: true,
			templateUrl: 'js/templates/link-picker.tpl.html',
			controller:'LinkPickerController',
			controllerAs: 'pickerVm',
			scope: {
				model: '=ngModel',
				relation: '@relation'
			},
			bindToController: true,
			link: function(scope,elem,attrs, Controller) {
				scope.$on('change', function(){
					Controller.closePicker();
				});
			}
		};
	}

	function LinkPickerController($scope, RelationService){
		var vm = this;

		this.popupOpened = false;
		this.items = [];

		this.openPicker = openPicker;
		this.closePicker = closePicker;

        var initializing = true;
		$scope.$watch('pickerVm.model', function(value) {
            if(initializing){
                initializing = false;
                RelationService.get(vm.relation)
                    .then(
                        function (data){
                            vm.items = data.items;
                        });
            }
            if(value && value.link.length!==0) {
            	vm.model.is_internal = (vm.model.link.indexOf('http')===-1);
            }
        },true);

		function openPicker(){
			vm.popupOpened = true;
		}
		function closePicker(){
			vm.popupOpened = false;
		}
	}
	LinkPickerController.$inject = ['$scope','RelationService'];

})();