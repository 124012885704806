(function () {

	'use strict';

	angular.module('services.password', [])
		.service('PasswordService', PasswordService);

	function PasswordService(){

		var numbers = '0123456789';
		var lowerCase = 'abcdefghijklmnopqrstuvwxyz';
		var upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXY';
		var special = '`~!@#$%^&-_=+[{]}.';

		var opts;

		var RandomString = function () {
			this.content = '';
			this.shuffle = function () {
				var a = this.content.split(""),
			        n = a.length;

			    for(var i = n - 1; i > 0; i--) {
			        var j = Math.floor(Math.random() * (i + 1));
			        var tmp = a[i];
			        a[i] = a[j];
			        a[j] = tmp;
			    }
			    this.content = a.join("");
			};
		};

		function randomize() {
			var randomString = new RandomString();

			if(opts.specials){
				randomString.content += randomGroup(special, 3);
			}
			if(opts.lowerCase){
				randomString.content += randomGroup(lowerCase, 5);
			}
			if(opts.upperCase){
				randomString.content += randomGroup(upperCase, 5);
			}
			if(opts.numbers){
				randomString.content += randomGroup(numbers, 3);
			}

			randomString.shuffle();

			return randomString.content;

		}

		function randomGroup(arr, num) {
			var length = arr.length;
			var random = '';

			for (var i = num; i!==0; i--){
				var key = Math.floor((Math.random() * length) + 0);
				random += arr[key];
			} 
			return random;
		}

		this.generateRandom = function (options) {
			opts = {
				specials: true,
				lowerCase: true,
				upperCase: true,
				numbers: true
			};
			return randomize(opts);
		};
	}

})();