(function(){
	'use strict';

	angular.module('services.authentication', [])
		.service('AuthenticationService', AuthenticationService);

	function AuthenticationService($state, $http,$q, ActiveUserFactory, ENVIRONMENT){
		var svc = this;

		//Public methods
        this.authenticate = authenticate;
        this.login = login;
        this.logoff = logoff;

        //Implementation
        function authenticate(){
            var promise = $http.get(ENVIRONMENT.AUTH_URL+'/login', { withCredentials:ENVIRONMENT.WITH_CREDENTIALS });
            promise.then(function(success){
                ActiveUserFactory.setActiveUser(success.data);
            });
            return promise;
        }
        function login(object) {
            var deferred = $q.defer();
            $http.post( ENVIRONMENT.AUTH_URL+'/login' , object, { withCredentials: ENVIRONMENT.WITH_CREDENTIALS  })
                .then(function(success){
                    if(success.data.user.manager){
                        ActiveUserFactory.setActiveUser();
                        deferred.resolve(success.data);
                    } else {
                        deferred.reject(success.data);
                    }
                },function(err){
                    deferred.reject(err.data);
                });
            return deferred.promise;
        }
        function logoff() {
            $http.get( ENVIRONMENT.AUTH_URL+'/logout', { withCredentials: ENVIRONMENT.WITH_CREDENTIALS  })
                .success(function() {
                    $state.go('loginPage');
                });
        }

	}
	AuthenticationService.$inject = ['$state', '$http','$q','ActiveUserFactory', 'ENVIRONMENT'];
})();