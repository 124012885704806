(function(){
	'use strict';

	angular.module('services.notification', [])
		.service('NotificationService', NotificationService);

	function NotificationService($timeout){
		var svc = this;

		this.success = [];
		this.errors = [];

		this.pushError = pushError;
		this.pushSuccess = pushSuccess;
		this.popError = popError;
		this.popSuccess = popSuccess;

		function pushError(message) {
			var index;

            if( svc.errors.indexOf(message) === -1 ){ 
            	svc.errors.push(message); 
            	index = svc.errors.length - 1;

            	$timeout(function(){
            		svc.popError(index);
            	}, 2000);
            }
		}
		function pushSuccess(message) {
			var index;

			if( svc.success.indexOf(message) === -1 ){ 
            	svc.success.push(message); 
            	index = svc.success.length - 1;

            	$timeout(function(){
            		svc.popSuccess(index);
            	}, 2000);
            }
		}
		function popError (index) {
			svc.errors.splice(index, 1);
		}
		function popSuccess (index) {
			svc.success.splice(index,1);
		}
	}
	NotificationService.$inject = ['$timeout'];

})();