(function(){
	'use strict';

	angular.module('loginPage', [])
		.config(config)
		.controller('LoginPageController', LoginPageController);

	function config($stateProvider){
		$stateProvider
			.state('loginPage', {
				url: '/login',
				templateUrl: 'js/templates/login-page.tpl.html',
				controller: 'LoginPageController',
				controllerAs: 'vm'
			});
	}
	config.$inject = ['$stateProvider'];

	function LoginPageController($rootScope, $state, LoginService){
	    var vm = this;

        //Bindables
        this.loginDetails = {};
        this.errorMessage = "";

        this.keyPress = keyPress;
        this.performLogin = performLogin;

        (function init() {
            $rootScope.hideFrame = true;
        })();

        //Implementation
        function keyPress(event){
            if(event.keyCode === 13){
                vm.performLogin();
            }
        }

        function performLogin(){
            LoginService.login(vm.loginDetails)
                .then(function(success){
                    $rootScope.hideFrame = false;
                    $state.go('users');
                    //console.log(success);
                }, function (err){
                    switch(err.status){
                        case 401: 
                            vm.errorMessage = "Username or password incorrect";
                            break;
                        case 400:
                            vm.errorMessage = "Enter your username and password";
                            break;
                        default:
                            vm.errorMessage = "Oops. Something went wrong. Try logging in again.";
                            break;
                    }
                });
        }

	}
	LoginPageController.$inject = ['$rootScope', '$state', 'LoginService'];

})();