(function(){
	'use strict';

	angular.module('services.login', [])
		.service('LoginService', LoginService);

	function LoginService(AuthenticationService){
		var svc = this;

        this.login = login;

        function login (object){
        	return AuthenticationService.login(object);
        }

	}
	LoginService.$inject = ['AuthenticationService'];
})();