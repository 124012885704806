(function(){
	'use strict';

	angular.module('services.MainMenu', [])
		.factory('MainMenuService', MainMenuService);

	function MainMenuService(ActiveUserService,MESSAGES){
      var svc = {
        menu:[],
        logoff: {}
      };
      (function init(){
        //Just initialize the service
        svc.menu = [
          [{
            title: 'Users',
            route: 'users',
            active: 'users',
            icon: 'users'
          }]
        ];
        //Observing the service, only interested in the type of notification, not in the body
        ActiveUserService.registerObserver(function(type /*,body*/){
          if(type === MESSAGES.ACTIVE_USER_LOADED){
            var user = ActiveUserService.getActiveUser();
            svc.logoff = {
              title: 'Logout "' + user.firstname + ' ' + user.surname + '"'
            };
          }
        });
      })();
      return svc;

	}
	MainMenuService.$inject = ['ActiveUserFactory','MESSAGES'];
})();