(function(){
	'use strict';

	angular.module('JuliusCP', [
		'ui.router',
		'ngResource',
		'utils.environment',
		'utils.messages',
		'dndLists',
		'providers.authState',

		//Common filters
		'filters.filename',
		'filters.formatTitle',

		//Common services
		'services.http',
		'services.event',
		'services.relations',
		'services.notification',
		'services.confirmation',
		'services.authentication',
		'services.localisation',
		'services.password',
		'services.activeUser',

		//Common resources
		'services.MainMenu',
		'resources.user',

		//Component directives
		'directives.dropdown',
		'directives.navigation',
		'directives.confirmation',
		'directives.notifier',
		'directives.editor',
		'directives.actionBar',
		'directives.relationPicker',
		'directives.pagination',
		'directives.linkPicker',

		//Domain modules
		'modules.loginModule',
		'modules.admin'
	]);

	angular.module('JuliusCP')
		.config(config)
		.run(runBlock);

	function config($urlRouterProvider){
		$urlRouterProvider.otherwise('/users');
	}

	function runBlock(AuthenticationService, $state, $rootScope, LocalisationService, ENV){
		$rootScope.env = ENV;
		$rootScope.transl = LocalisationService.transl;
		AuthenticationService.authenticate()
			.error(function(err){
				$state.go('loginPage');
			});
	}
	runBlock.$inject = ['AuthenticationService', '$state', '$rootScope', 'LocalisationService', 'ENVIRONMENT'];
})();