(function(){
	'use strict';

	angular.module('directives.actionBar', [])
		.directive('actionBar', actionBar)
		.controller('ActionBarController', ActionBarController);

	function actionBar(){
		function link(scope, elem, attrs, Controller){

		}

		return {
			restrict: 'AE',
			replace: true,
			templateUrl: 'js/templates/actionbar.tpl.html',
			link: link,
			controller: 'ActionBarController',
			controllerAs: 'barVm',
			scope: {
				isNew: '='
			},
			bindToController: true
		};
	}

	function ActionBarController(EventService, ConfirmationService, MESSAGES){
		var vm = this;

		this.dispatchSave = dispatchSave;
		this.dispatchDelete = dispatchDelete;
		this.dispatchCancel = dispatchCancel;

		function dispatchSave(){
			ConfirmationService.confirmThis({question:"Are you sure you want to save this?", icon:"save"}, function(confirmed){
				if(confirmed){
					EventService.notify(MESSAGES.SAVE_MODEL);
				}
			});
			
		}
		function dispatchDelete(){
			ConfirmationService.confirmThis({question:"Are you sure you want to delete this?", icon:"trash"}, function(confirmed){
				if(confirmed){
					EventService.notify(MESSAGES.DELETE_MODEL);
				}
			});
			
		}
		function dispatchCancel(){
			EventService.notify(MESSAGES.CANCEL_MODEL);
		}
	}
	ActionBarController.$inject = ['EventService', 'ConfirmationService','MESSAGES'];

})();