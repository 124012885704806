angular.module('utils.messages', [])
	.constant('MESSAGES', {
	    ACTIVE_USER_LOADED : 'ACTIVE_USER_LOADED',
	    SAVE_MODEL: 'SAVE_MODEL',
	    DELETE_MODEL: 'DELETE_MODEL',
	    CANCEL_MODEL: 'CANCEL_MODEL',

	    
	    ARTICLE_TYPES_LOADED: 'ARTICLE_TYPES_LOADED'
	});
