(function () {
    'use strict';
    angular.module('filters.formatTitle', [])
        .filter('formatTitle', formatTitle);

    function formatTitle(){
        return function(input){
            return input.substring(0,1).toUpperCase() + input.substring(1,input.length);
        };
    }
    formatTitle.$inject = ['$sce'];
})();