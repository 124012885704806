(function () {
    'use strict';

    angular.module('providers.authState',[])
        .provider('authState', AuthStateProvider);

    function AuthStateProvider($stateProvider) {
        this.state = state;
        function state(name, _opts) {
            var obj = {
                loggedIn: function ($http, $state, AUTH) {
                    return $http.get(AUTH.url+'auth/login', { withCredentials:true })
                        .then(function(s){},function(e){$state.go('login');});
                }
            };
            _opts.resolve = _concatObjects(_opts.resolve, obj);
            $stateProvider.state(name, _opts);
        }
        this.$get = function(){
            return $stateProvider;
        };
    }
    AuthStateProvider.$inject = ["$stateProvider"];

    function _concatObjects() {
        var ret = {};
        var len = arguments.length;
        for (var i = 0; i < len; i++) {
            for (var p in arguments[i]) {
                if (arguments[i].hasOwnProperty(p)) {
                    ret[p] = arguments[i][p];
                }
            }
        }
        return ret;
    }

})();