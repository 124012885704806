(function () {
    'use strict';
    angular.module('filters.filename', [])
        .filter('filename', filename);

    function filename(){
        return function(string){
            // var index = string.indexOf('');

            // return string.substring(index + 7);
            return string;
        };
    }
    filename.$inject = ['$sce'];
})();