(function(){
	'use strict';

	angular.module('directives.pagination',[])
		.directive('pagination', pagination)
		.controller('PaginationController', PaginationController);

	function pagination(){
		return {
			restrict: 'E',
			replace: true,
			scope: {
				model: '=ngModel',
				func: '&',
				active: '=',
				maxPages: '='
			},
			bindToController: true,
			templateUrl: 'js/templates/pagination.tpl.html',
			controller: 'PaginationController',
			controllerAs: 'paginationVm',
			link: function(){

			}
		};
	}
	PaginationController.$inject = ['$scope', '$state'];
	function PaginationController($scope, $state) {
		var vm = this;

		this.moveToPage = moveToPage;
		this.previousPage = previousPage;
		this.nextPage     = nextPage;

		(function init(){

			$scope.$watch('paginationVm.model', function (newValue){

				if(newValue!==undefined){
					var lastPageNr       = Math.ceil(vm.model.total / parseInt(vm.model.limit));
					var startPageNr      = vm.active - ((vm.maxPages / 2) - 1);
					if (startPageNr > (lastPageNr - vm.maxPages)) {
						startPageNr = lastPageNr - vm.maxPages;
						if (1 > startPageNr) {
							startPageNr = 1;
						}
					}

					if (1 > startPageNr) {
						startPageNr = 1;
					}

					vm.pages = [];
					for (var pNr = startPageNr; pNr <= lastPageNr && pNr < (startPageNr + vm.maxPages); pNr++) {
						vm.pages.push(pNr);
					}
				}
			});
		})();

		function moveToPage(page){
			vm.func(page);
		}
		function nextPage(){
			vm.moveToPage({page:parseInt(vm.active)+1});
		}
		function previousPage(){
			vm.moveToPage({page:parseInt(vm.active)-1});
		}
	}

})();